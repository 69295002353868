import Web3 from "web3";

import { ContractFuncExecutor } from "./contractexecutor/ContractExecutor";
import { getAnalytics, logEvent } from "firebase/analytics";

class ContractFuncWrapper {
    /**
     * 
     * @param {*} web3InstanceGetter () => { returns web3Instance } 
     * @param {*} unityContext 
     * @param {*} unityGameObjectName 
     * @param {*} entryPointMethod 
     * @param {*} reportTxEventMethod 
     * @param {string} multicallAddress
     */
    constructor(web3InstanceGetter, unityContext, unityGameObjectName, entryPointMethod, reportTxEventMethod, multicallAddress) {
        this.web3Instance = web3InstanceGetter;
        this.unityContext = unityContext;
        this.cbGameObjectName = unityGameObjectName;
        this.entryPoint = entryPointMethod;
        this.reportTxEventMethod = reportTxEventMethod;

        this.executeDelegator = {
            onTxSending: (callID, payload) => {
                this.unityReportTxEvent(callID, "sending", { ...payload });
            },
            onTxSent: (callID, payload) => {
                this.unityReportTxEvent(callID, "sent", { ...payload });
            },
            onTxHashed: (callID, txHash) => {
                this.unityReportTxEvent(callID, "transactionHash", { transactionHash: txHash });
            },
            onTxGetReceipt: (callID, receipt) => {
                this.unityReportTxEvent(callID, "receipt", { receipt });
            },
            onTxConfirmed: (callID, confNumber, receipt, latestBlockHash) => {
                this.unityReportTxEvent(callID, "confirmation",
                    {
                        confNumber,
                        receipt,
                        latestBlockHash,
                    },
                );
            },
            onTxError: (callID, error) => {
                this.unityReportTxEvent(callID, "error", { error });
                this.unitySend(callID, {}, error);
            },
            resultCallback: (callID, result) => {
                this.unitySend(callID, result);
            },
        }

        this.rpcCallBatchingDelegator = {
            /**
             * @param {int} numberOfCalls number of batched calls
             * @param {int} numberOfCallSavedByL2Batching number of calls saved by L2 batching
             */
            reportAnalytic: (numberOfCalls, numberOfCallSavedByL2Batching) => {
                try {
                    const analytics = getAnalytics();
                    logEvent(analytics, 'rpc_call_batching_execute_v1', {
                        calls: numberOfCalls,
                        savedByL2Batching: numberOfCallSavedByL2Batching,
                    });
                } catch (err) { }
            }
        }
        this.executor = new ContractFuncExecutor(
            this.web3Instance,
            this.executeDelegator,
            this.rpcCallBatchingDelegator,
            multicallAddress,
        );
        this.executor.startBatchingInterval();
    }

    executeContractFunction(callID, funcName, args) {
        if (!this.executor.isFunctionValid(funcName)) {
            throw new Error(`function ${funcName} not found`);
        }

        // batching...
        if (this.executor.isReadOnlyFunction(funcName) &&
            this.executor.isBatchableFunction(funcName)) {
            this.executor.enqueueBatchable(funcName, callID, args);
            return;
        }

        try {
            this.executor.executeFunction(funcName, callID, args);
        } catch (err) {
            this.unitySend(callID, {}, err);
            return;
        }

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'rpc_call_execute_v1', {
                name: funcName,
                count: 1,
            });
        } catch (err) { }
    }

    unityReportTxEvent(callID, eventName, payload = {}) {
        const logTag = "[JS::ContractFuncWrapper::unityReportTxEvent]";
        console.log(`${logTag} callID: ${callID} payload: ${JSON.stringify(payload, null, "\t")}`);

        this.unityContext.send(
            this.cbGameObjectName,
            this.reportTxEventMethod,
            JSON.stringify(
                {
                    callID,
                    eventName,
                    payload
                }),
        );
    }

    unitySend(callID, args = null, err = null) {
        const logTag = "[JS::ContractFuncWrapper::unitySend]";
        let returnValue = {
            callID,
        };
        if (args != null) {
            returnValue = {
                ...returnValue,
                ...args,
            };
        }
        if (err != null) {
            returnValue = {
                ...returnValue,
                error: err,
            };
        }

        console.log(`${logTag} callID: ${callID} returns...`);
        console.dir(returnValue);

        this.unityContext.send(
            this.cbGameObjectName,
            this.entryPoint,
            JSON.stringify(returnValue),
        );
    }
}

export { ContractFuncWrapper }