import axios from "axios";
import StorageKey from "../model/StorageKey";
import qs from "qs"

async function GetAccessToken(code) {
    const response = await axios({
        url: process.env.REACT_APP_BITKUB_NEXT_ACCOUNT_ENDPOINT + "/api/oauth2/access_token",
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
            redirect_uri: window.location.protocol + "//" + window.location.host + "/oauth/callback",
            code: code,
            client_id: process.env.REACT_APP_BITKUB_NEXT_CLIENT_ID,
            grant_type: "authorization_code"
        })
    });
    localStorage.setItem(StorageKey.BITKUB_NEXT_AUTH, JSON.stringify(response.data));
    return response.data;
}

export default GetAccessToken;