import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import GetAccessToken from "../../api/GetAccessToken";
import LoginType from "../../model/LoginType";
import StorageKey from "../../model/StorageKey";
import { getAnalytics, logEvent } from "firebase/analytics";

import { Loading } from "../common/components";

function AuthenticationCallbackScreen() {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const loginType = localStorage.getItem(StorageKey.LONGIN_TYPE);

    const getBitkubNextAccessToken = useCallback(async (code) => {
        // log event - bitkubnext oauth callback
        const analytics = getAnalytics();

        try {
            if (analytics) {
                logEvent(analytics, 'bkn_get_accesstoken_start');
            }

            await GetAccessToken(code);

            if (analytics) {
                logEvent(analytics, 'bkn_get_accesstoken_success');
            }

            navigateToGame();
        } catch (e) {
            console.error(e);

            if (analytics) {
                logEvent(analytics, 'bkn_get_accesstoken_error', { error: e });
            }

            navigateToLogin()
        }
    }, []);

    useEffect(() => {
        let code = query.get("code");
        if (code && loginType == LoginType.BITKUB_NEXT) {
            // log event - bitkubnext oauth callback
            const analytics = getAnalytics();

            if (analytics) {
                logEvent(analytics, 'bkn_oauth_callback');
            }

            getBitkubNextAccessToken(code);
        } else {
            navigateToLogin();
        }
    }, [getBitkubNextAccessToken]);

    const navigateToLogin = () => {
        history.replace("/login")
    }

    const navigateToGame = () => {
        // log event - bitkubnext oauth callback
        const analytics = getAnalytics();
        if (analytics) {
            logEvent(analytics, 'bkn_navigate_to_game');
        }
        history.replace("/")
    }

    return (
        <Loading isLoaded={false} />
    )
}

export default AuthenticationCallbackScreen;