import axios from "axios";
import QueryString from "qs";
import StorageKey from "../model/StorageKey";

async function RefreshToken() {
    const auth = JSON.parse(localStorage.getItem(StorageKey.BITKUB_NEXT_AUTH));
    if (auth && auth.refresh_token) {
        const response = await axios({
            url: process.env.REACT_APP_BITKUB_NEXT_ACCOUNT_ENDPOINT + "/api/oauth2/access_token",
            method: 'POST',
            headers: {
                "Authorization": "Basic " + btoa(process.env.REACT_APP_BITKUB_NEXT_CLIENT_ID),
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: QueryString.stringify({
                client_id: process.env.REACT_APP_BITKUB_NEXT_CLIENT_ID,
                refresh_token: auth.refresh_token,
                grant_type: "refresh_token"
            })
        });
        localStorage.setItem(StorageKey.BITKUB_NEXT_AUTH, JSON.stringify(response.data));
        return response.data;
    } else {
        throw new Error("Unauthorized");
    }
}

export default RefreshToken;