import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StorageKey from "../model/StorageKey";
import RefreshToken from "../api/RefreshToken";
import LoginType from "../model/LoginType";

function useCheckAuthentication() {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const loginType = localStorage.getItem(StorageKey.LONGIN_TYPE);

    const handleBitkubNextLoginType = useCallback(async () => {
        try {
            await RefreshToken();
            setIsAuthenticated(true);
        } catch (e) {
            navigateToLogin();
        }
    }, []);

    useEffect(() => {
        if (!loginType) {
            navigateToLogin();
        } else if (loginType === LoginType.BITKUB_NEXT) {
            handleBitkubNextLoginType()
        } else {
            setIsAuthenticated(true);
        }
    }, [handleBitkubNextLoginType]);

    const navigateToLogin = () => {
        setIsAuthenticated(false);
        history.replace("/login")
    }

    return isAuthenticated;
}

export default useCheckAuthentication;