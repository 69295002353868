import {UnityEvent, UnityGameObject, UnityMethod} from "../model/UnityConstant";
import StorageKey from "../model/StorageKey";
import GetUserInfo from "../api/GetUserInfo";
import RefreshToken from "../api/RefreshToken";

const REFRESH_TIMEOUT = 300 * 1000;

function RequestBitkubAuthenticationInfoHandler(unityContext, history) {
    const navigateToLogin = () => {
        history.replace("/login");
    }

    const intervalRefreshToken = () => {
        setInterval(async () => {
            const authInfo = await RefreshToken();
            unityContext.send(UnityGameObject.WebContainerListener, UnityMethod.OnUpdateToken, JSON.stringify(authInfo));
        }, REFRESH_TIMEOUT);
    }

    const fetchUserInfo = async () => {
        try {
            const userInfo = await GetUserInfo();
            const authInfo = JSON.parse(localStorage.getItem(StorageKey.BITKUB_NEXT_AUTH));

            if (userInfo && authInfo) {
                const response = JSON.stringify({
                    userInfo: userInfo,
                    authInfo: authInfo
                });
                unityContext.send(UnityGameObject.WebContainerListener, UnityMethod.OnReceiveBitkubAuthenticationInfo, response);
                intervalRefreshToken();
            } else {
                navigateToLogin();
            }
        } catch (e) {
            navigateToLogin();
        }
    }

    unityContext.on(UnityEvent.RequestBitkubAuthenticationInfo, () => {
        fetchUserInfo();
    });
}

export default RequestBitkubAuthenticationInfoHandler;