import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import swal from "sweetalert2";
import AppRouter from "./AppRouter";

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("root")
);

console.log("Environment : " + process.env.REACT_APP_ENV_NAME);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.alert = (msg) => {
//   swal.fire({
//     icon: "error",
//     title: "Oops...",
//     text: `Something went wrong!\n${msg}`,
//   });
// };
