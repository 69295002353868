export const bitKubMainNet = {
    "nativeCurrency": {
        "name": "KUB",
        "symbol": "KUB",
        "decimals": 18
    },
    "blockExplorerUrls": ["https://bkcscan.com"],
    "chainId": `0x${Number(96).toString(16)}`,
    "chainName": "Bitkub Chain",
    "rpcUrls": ["https://rpc.bitkubchain.io"]
}

export const bitKubTestNet = {
    "nativeCurrency": {
        "name": "KUB",
        "symbol": "KUB",
        "decimals": 18
    },
    "blockExplorerUrls": ["https://testnet.bkcscan.com"],
    "chainId": `0x${Number(25925).toString(16)}`,
    "chainName": "Bitkub Chain - Testnet",
    "rpcUrls": ["https://rpc-testnet.bitkubchain.io"]
}


export function getChainSetting() {
    console.log("process.env.REACT_APP_ENV_NAME: ", process.env.REACT_APP_ENV_NAME)
    if (process.env.REACT_APP_ENV_NAME == "prod") {
        return bitKubMainNet
    }
    return bitKubTestNet
}