import React from "react"
import LoginType from "../../model/LoginType";
import StorageKey from "../../model/StorageKey";
import { getChainSetting } from "../../model/NetworksDetail";
import { useHistory } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./login.css";
import { setupChainNetworkMetaMask, getBalanceMetaMask, getAccounts } from "../../utility/metamask";
import swal from "sweetalert2";

const bgImg = process.env.PUBLIC_URL + '/assets/images/game/bg.jpg';
const btnMetaMaskImg = process.env.PUBLIC_URL + '/assets/images/game/btn_metamask.png';
const btnBitKubNextImg = process.env.PUBLIC_URL + '/assets/images/game/btn_bitkubnext.png';
const logoImg = process.env.PUBLIC_URL + '/assets/images/game/Logo.png';
const licenseImg = process.env.PUBLIC_URL + '/assets/images/game/license.png';

function LoginScreen() {
    const history = useHistory();
    localStorage.clear();

    const getBitkubNextOAuth2URL = () => {
        const url = new URL(process.env.REACT_APP_BITKUB_NEXT_ACCOUNT_ENDPOINT + "/oauth2/authorize")
        url.searchParams.append("response_type", "code")
        url.searchParams.append("client_id", process.env.REACT_APP_BITKUB_NEXT_CLIENT_ID)
        url.searchParams.append("redirect_uri", window.location.protocol + "//" + window.location.host + "/oauth/callback")
        return url;
    }

    const saveLoginType = (loginType) => {
        localStorage.setItem(StorageKey.LONGIN_TYPE, loginType);
    }

    const logEventLogin = (loginType) => {
        const analytics = getAnalytics();
        if (analytics) {
            logEvent(analytics, 'login', { login_type: loginType });
        }
    }

    const onMetamaskClicked = async () => {
        saveLoginType(LoginType.METAMASK);

        // log event - login with metamask
        logEventLogin(LoginType.METAMASK);

        const chainDetail = getChainSetting()
        const valid = await setupChainNetworkMetaMask(chainDetail)
        if (!valid) return
        history.replace("/");
    }

    const onBitkubNextClicked = async () => {
        saveLoginType(LoginType.BITKUB_NEXT);

        // log event - login with bitkubnext
        logEventLogin(LoginType.BITKUB_NEXT);

        window.open(getBitkubNextOAuth2URL(), "_self")
    }

    return (
        <div className="login-container" style={{ backgroundImage: `url(${bgImg})` }}>
            <img src={logoImg} width="30%" />
            <input type="image" src={btnMetaMaskImg} onClick={onMetamaskClicked} style={{ width: 430 }} />
            <input type="image" src={btnBitKubNextImg} onClick={onBitkubNextClicked} style={{ width: 430 }} />
            <div id="license-login">
                <div className="license">
                    <footer>
                        <p>©Copyright 2021 Extend Interactive Co., Ltd. All right reserved.</p>
                    </footer>
                </div>
            </div>
        </div >
    )
}

export default LoginScreen;