import { Component } from "react";

const logoImg = process.env.PUBLIC_URL + '/assets/images/game/Logo.png';
const bgImg = process.env.PUBLIC_URL + '/assets/images/game/bg.jpg';

export default function MaintenanceScreen() {
    return (
        <div className="login-container" style={{ backgroundImage: `url(${bgImg})` }}>
            <img src={logoImg} width="30%" />
            <h2>Sorry, we are down for Maintenance right now.</h2>
            <div id="license-login">
                <div className="license">
                    <footer>
                        <p>©Copyright 2021 Extend Interactive Co., Ltd. All right reserved.</p>
                    </footer>
                </div>
            </div>
        </div >
    )
}