import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import LoginScreen from "./component/login/LoginScreen"
import App from "./App"
import AuthenticationCallbackScreen from "./component/auth/AuthenticationCallbackScreen"
import MaintenanceScreen from "./component/maintenance"

function AppRouter() {
    return (
        <BrowserRouter>
            <Route exact path="/" component={App} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/oauth/callback" component={AuthenticationCallbackScreen} />
            <Route path="/maintenance" component={MaintenanceScreen} />
        </BrowserRouter>
    )
}

export default AppRouter;