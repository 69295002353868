export const UnityGameObject = {
    WebContainerListener: "WebContainerListener"
}

export const UnityEvent = {
    RequestLoginType: "RequestLoginType",
    RequestBitkubAuthenticationInfo: "RequestBitkubAuthenticationInfo",
    NavigateToLogin: "NavigateToLogin",
    RequestClientIdentity: "RequestClientIdentity",
    RequestTier1Captcha: "RequestTier1Captcha",
    RequestTier2Captcha: "RequestTier2Captcha",
    CheckIfSetForceNoShadow: "CheckIfSetForceNoShadow",
}

export const UnityMethod = {
    OnReceiveRequestLoginType: "OnReceiveRequestLoginType",
    OnReceiveBitkubAuthenticationInfo: "OnReceiveBitkubAuthenticationInfo",
    OnUpdateToken: "OnUpdateToken",
    OnReceiveChainChanged: "OnReceiveChainChanged",
    RequestClientIdentityCallback: "RequestClientIdentityCallback",
    RequestTier1CaptchaCallback: "RequestTier1CaptchaCallback",
    RequestTier2CaptchaCallback: "RequestTier2CaptchaCallback",
    CheckIfSetForceNoShadowCallback: "CheckIfSetForceNoShadowCallback"
}