// in an ES6 environment:
import { ClientJS } from 'clientjs';
import { v4 as uuidv4 } from "uuid";

export function getMachineFingerprint() {
    const client = new ClientJS();

    const args = {
        "isMac": client.isMac(), // Check For Mac
        "isLinux": client.isLinux(), // Check For Linux
        "isWindows": client.isWindows(), // Check For Windows
        "isUbuntu": client.isUbuntu(), // Check For Ubuntu
        "getOSVersion": client.getOSVersion(), // Get OS Version
        "getOS": client.getOS(), // Get OS Version
        "getDevice": client.getDevice(), // Get Device
        "getDeviceType": client.getDeviceType(), // Get Device Type
        "getDeviceVendor": client.getDeviceVendor(), // Get Device Vendor
        "getCPU": client.getCPU(), // Get CPU Architecture
        "isMobile": client.isMobile(), // Check For Mobile
        "getScreenPrint": client.getScreenPrint(), // Get Screen Print
        "getColorDepth": client.getColorDepth(), // Get Color Depth
        "getCurrentResolution": client.getCurrentResolution(), // Get Current Resolution
        "getAvailableResolution": client.getAvailableResolution(), // Get Available Resolution
        "getDeviceXDPI": client.getDeviceXDPI(), // Get Device XDPI
        "getDeviceYDPI": client.getDeviceYDPI(), // Get Device YDPI
        "getTimeZone": client.getTimeZone(), // Get Time Zone
        "getLanguage": client.getLanguage(), // Get User Language
        "getCanvasPrint": client.getCanvasPrint(), // Get Canvas Print
        "getFingerprint": client.getFingerprint(), // Get fingerprint
    };

    const fingerprint = client.getCustomFingerprint(Object.values(args));
    if (process.env.REACT_APP_ENV_NAME === "dev") {
        console.log(args);
        console.log("machine fingerprint:", fingerprint);
    }

    return fingerprint;
}

export function generateScreenUID() {
    return uuidv4();
}