import React from "react"

const bgImg = process.env.PUBLIC_URL + '/assets/images/game/bg.jpg';
const logoImg = process.env.PUBLIC_URL + '/assets/images/game/Logo.png';
const loadingImg = process.env.PUBLIC_URL + '/assets/images/game/txt_loading.png';
const chickenLoadingImg = process.env.PUBLIC_URL + '/assets/images/game/chicken_loading.gif';


export const Loading = ({ isLoaded, progress }) => {
    return (
        <div className="login-container" style={{
            backgroundImage: `url(${bgImg})`,
            display: !isLoaded ? "block" : "none"
        }}>
            <div className="loading-wrapper">
                <div>
                    <img src={logoImg} id="morning-moon-load-logo" />
                </div>
                <div>
                    <img src={chickenLoadingImg} width="120" height="120" />
                </div>
                <div>
                    {progress <= 0.89 ?
                        <>
                            <img src={loadingImg} />
                            <div className="progress-wrapper">
                                <p className="progress-number">{progress ? (progress * 100).toFixed(2) : 0}%</p>
                            </div>
                        </> :
                        <p className="progress-initialize">Initializing...</p>
                    }
                </div>
                <div>
                    <p className="loading-workaround">If you encounter any problem during the loading process,<br />
                        please clear your web browser cache and refresh the website.</p>
                </div>
                <div className="license">
                    <footer>
                        <p>© Copyright 2021 Extend Interactive Co., Ltd. All right reserved.</p>
                    </footer>
                </div>
            </div>
        </div >
    )
}

export const ErrorPage = ({ isLoaded, errorMessage }) => {
    return (
        <div className="login-container" style={{
            backgroundImage: `url(${bgImg})`,
            display: !isLoaded ? "block" : "none"
        }}>
            <div className="loading-wrapper">
                <div>
                    <img src={logoImg} id="morning-moon-load-logo" />
                </div>
                <div>
                    <h1 id="error-msg">Something went wrong! </h1>
                    <h1 id="error-msg">Please try again later or check if your systems meet the requirement.</h1>
                    <button className="button-see-detail" onClick={() => { alert(errorMessage); }} >view error</button>
                </div>
                <div className="license">
                    <footer>
                        <p>© Copyright 2021 Extend Interactive Co., Ltd. All right reserved.</p>
                    </footer>
                </div>
            </div>
        </div>
    )
}
