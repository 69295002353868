import swal from "sweetalert2";
import StorageKey from "../model/StorageKey";


export const setupChainNetworkMetaMask = async (bitKubChain) => {
    if (typeof window.web3 === 'undefined') {
        await swal.fire({
            title: "Web browswer unsupport",
            text: "Please install metamask",
        })
        return false
    }

    if (window.web3.currentProvider.isMetaMask !== true) {
        await swal.fire({
            title: "Web browswer unsupport",
            text: "Metamask is not active",
        })
        return false
    }

    if (window.ethereum.isConnected()) {
        if (window.ethereum.networkVersion != parseInt(bitKubChain.chainId)) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: bitKubChain.chainId }],
                })
                return true
            } catch (switchError) {
                console.error(switchError)
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [bitKubChain],
                        })
                        return true
                    } catch (addError) {
                        console.log("add", addError)
                        swal.fire({
                            title: "Network Chain",
                            text: "Please add bitkub chain network",
                        })
                        return false
                    }
                } else {
                    console.log("switchError", switchError)
                    await swal.fire({
                        title: "Network Chain",
                        text: "Please switch network chain",
                    })
                    return false
                }
            }
        }
    }

    return true
}

export const getAccounts = async () => {
    let accounts = []
    try {
        accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    } catch (error) {
        return { accounts: accounts, error: error }
    }
    return { accounts: accounts, error: null }
}

export const getBalanceMetaMask = async (accounts) => {
    let coinBalance = 0
    try {
        const balance = await window.ethereum.request({ method: 'eth_getBalance', params: [accounts[0], "latest"] })
        coinBalance = parseInt(balance, 16)

        return { coinBalance, error_balance: null }
    }
    catch (error) {
        console.log(error)
        return { coinBalance, error_balance: error }
    }

}