import StorageKey from "../model/StorageKey";
import {UnityGameObject, UnityEvent, UnityMethod} from "../model/UnityConstant";

function RequestLoginTypeHandler(unityContext) {
    unityContext.on(UnityEvent.RequestLoginType, () => {
        const loginType = localStorage.getItem(StorageKey.LONGIN_TYPE);
        unityContext.send(UnityGameObject.WebContainerListener, UnityMethod.OnReceiveRequestLoginType, loginType);
    });
}

export default RequestLoginTypeHandler;