import axios from "axios";
import StorageKey from "../model/StorageKey";

async function GetUserInfo() {
    const auth = JSON.parse(localStorage.getItem(StorageKey.BITKUB_NEXT_AUTH));
    if (auth && auth.access_token) {
        const response = await axios({
            url: process.env.REACT_APP_BITKUB_NEXT_API_ENDPOINT + "/accounts/auth/info",
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.access_token
            }
        });
        localStorage.setItem(StorageKey.BITKUB_NEXT_USER_INFO, JSON.stringify(response.data.data))
        return response.data.data
    } else {
        throw new Error("Unauthorized");
    }
}

export default GetUserInfo;